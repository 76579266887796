import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import HTMLReactParser from "html-react-parser";

const PrivacyPolicy = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  let captchaKey = Cookies.get("captchaKey");

  const [hoverColor, setHoverColor] = useState(false);

  const handleMouseEnter = () => {
    setHoverColor(true);
  };

  const handleMouseLeave = () => {
    setHoverColor(false);
  };

  const [pageContent, setPageContent] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/page/staticpage/privacy-policy`);
      setLoading(false);
      setPageContent(response.data.response);
    } catch (error) {
      setLoading(false);
      console.log(
        "Cannot get job description through applied job section at job seeker"
      );
    }
  };

  useEffect(() => {
    getData();
  }, [])
  

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const newErrors = {};

      if (formData.name === "") {
        newErrors.name = "Name is required";
      }
      if (formData.email === "") {
        newErrors.email = "Email is required";
      } else if (!isValidEmail(formData.email)) {
        newErrors.email = "Invalid email format";
      }
      if (formData.subject === "") {
        newErrors.subject = "Subject is required";
      }
      if (formData.message === "") {
        newErrors.message = "Message is required";
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = "Please verify captcha";
      }

      setErrors(newErrors);

      // Function to validate email format
      function isValidEmail(email) {
        // Use a regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      }

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/page/contact-us",
            formData
          );
          setLoading(false);
          if (response.data.status === 200) {
            Swal.fire({
              title: "Details submitted. We will contact you shortly!",
              icon: "success",
              confirmButtonText: "Close",
            });
            setFormData({
              ...formData,
              name: "",
              email: "",
              subject: "",
              message: "",
            });
          } else if (response.data.status === 500) {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        title: "Failed",
        text: "Could not submit your details. Please try again later!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
    console.log(formData);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="privacyPolicy">
            <div className="text-center PPSection1">
              <h1 className="">{pageContent.static_page_title}</h1>
              <h6 className="text-muted fw-normal">
                {" "}
                <Link to="/" style={{ color: "grey" }}>
                  Home
                </Link>{" "}
                /{pageContent.static_page_title}
              </h6>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="upperPart">
                    <p>
                      {pageContent.static_page_description && HTMLReactParser(pageContent.static_page_description) }
                    </p>
                    {/* <h4 className="mt-4">Login and Registering</h4>
                    <p>
                      You can login into the app by registering and by providing
                      your basic informations. This informations will never be
                      used without your permission.
                    </p> */}
                  </div>
                  {/* <div className="lowerPart">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Personal Data
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            while using our services,we may ask you to provide
                            us with certain personally identifiable information
                            that can be used to contact or identify you
                            ("personal data ").Personally identifiable
                            information may include ,but is not imited to.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Security Of Data
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            The security of your data is important to us,but
                            remember that no method of transmission over the
                            internet,or method of electronic storage is
                            100%secure.While we strive to use commercially
                            acceptable means to protect your personal Data.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Service Provider
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            We may employ third party companies and individuals
                            to facilitate our service to provide the service on
                            our behalf,to perform service related services or to
                            assist us in analyzing how our service is used.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="upperPart">
                    <p>
                      These third parties have access to your personal data only
                      to perform these task on our behalf and are obligated not
                      to disclose or use it for any other purpose
                    </p>
                  </div> */}
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="text-center pb-4 pt-2">
                        Send us a
                        <span className="textGradient">
                          {" "}
                          <span className="SubHaddingTxt"> Message</span>
                        </span>
                      </h2>
                      <form>
                        <div className="mb-4">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.name && "input-error"
                            }`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="name"
                            value={formData.name}
                            placeholder="Name*"
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <div className="text-danger">{errors.name}</div>
                          )}
                        </div>
                        <div className="mb-4">
                          <input
                            type="email"
                            className={`form-control ${
                              errors.email && "input-error"
                            }`}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name="email"
                            value={formData.email}
                            placeholder="Email*"
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <div className="text-danger">{errors.email}</div>
                          )}
                        </div>
                        <div className="mb-4">
                          <select
                            className={`form-select ${
                              errors.subject && "input-error"
                            }`}
                            aria-label="Default select example"
                            value={formData.subject}
                            name="subject"
                            onChange={handleChange}
                          >
                            <option>Please Select</option>
                            <option value="1">General Query</option>
                            <option value="2">Personal Feedback</option>
                            <option value="3">Pre Sale Question</option>
                            <option value="3">Support Issue</option>
                            <option value="3">Refund Issue</option>
                          </select>
                          {errors.subject && (
                            <div className="text-danger">{errors.subject}</div>
                          )}
                        </div>
                        <div className="mb-4">
                          <textarea
                            className={`form-control ${
                              errors.message && "input-error"
                            }`}
                            id="exampleFormControlTextarea1"
                            rows="5"
                            name="message"
                            value={formData.message}
                            placeholder="Message*"
                            onChange={handleChange}
                          ></textarea>
                          {errors.message && (
                            <div className="text-danger">{errors.message}</div>
                          )}
                        </div>
                        <div className="reCaptchaLogin mb-4">
                          <ReCAPTCHA
                            sitekey={captchaKey}
                            // sitekey="6Ld8bV8nAAAAAEp24xWlKsVFhVDYlBctFF50MI1x"
                            onChange={(value) => setIsCaptchaVerified(value)}
                          />
                          {errors.captcha && (
                            <div className="text-danger">{errors.captcha}</div>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn w-100"
                          onClick={handleClick}
                          style={{
                            backgroundColor: `${
                              secondaryColor &&
                              (hoverColor ? secondaryColor : primaryColor)
                            }`,
                          }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          SEND MESSAGE
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default PrivacyPolicy;
