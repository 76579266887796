import React, { useEffect, useState } from "react";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import Footer from "../element/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiKey from "../api/ApiKey";
import BaseApi from "../api/BaseApi";
// import Multiselect from "multiselect-react-dropdown";
import JoditEditor from "jodit-react";
import Select from "react-select";
import { useRef } from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const JSEditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [editProfile, setEditProfile] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [experience, setExperience] = useState([]);
  const [interest_categories, setInterest_categories] = useState([]);
  const [coverLetter, setCoverLetter] = useState([
    {
      title: "",
      description: "",
    },
  ]);
  const [selectedCV, setSelectedCV] = useState();
  // const [isCVPicked, setIsCVPicked] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState([]);
  const [oldDocuments, setOldDocuments] = useState([]);
  const [oldCertificates, setOldCertificates] = useState([]);
  const [docDownloadPath, setDocDownloadPath] = useState();
  const [downloadActive, setDownloadActive] = useState(false);

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  // const [updatedSkills, setUpdatedSkill] = useState([]);
  // const [updatedCat, setUpdatedCat] = useState([]);

  const editor = useRef(null);
  const navigate = useNavigate();
  const tokenKey = Cookies.get("tokenClient");
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  const mapKey = Cookies.get("mapKey");


  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false);

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true);
  };

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false);
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false);

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true);
  };

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false);
  };

  const [hoverThirdButtonColor, setHoverThirdButtonColor] = useState(false);

  const handleThirdButtonMouseEnter = () => {
    setHoverThirdButtonColor(true);
  };

  const handleThirdButtonMouseLeave = () => {
    setHoverThirdButtonColor(false);
  };

  const [hoverFourthButtonColor, setHoverFourthButtonColor] = useState(false);

  const handleFourthButtonMouseEnter = () => {
    setHoverFourthButtonColor(true);
  };

  const handleFourthButtonMouseLeave = () => {
    setHoverFourthButtonColor(false);
  };

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    location: "",
    contact: "",
    pre_location: "",
    skills: [],
    exp_salary: "",
    total_exp: "",
    company_about: "",
  });

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/candidates/editProfile",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );

      var selectedSkillsName = response.data.response.skills;

      if (selectedSkillsName) {
        // code to handel preselected skills
        var skillList = response.data.response.skillList;
        
        var SelectSkills = [];
        skillList.forEach((element) => {
          for (let i = 0; i < selectedSkillsName.length; i++) {
            if (selectedSkillsName[i] === element.name) {
              let obj = {
                value: element.id,
                label: element.name,
              };

              SelectSkills.push(obj);
            }
          }
        });
      }
      console.log("object");

      console.log(SelectSkills);

      setSelectedSkills(SelectSkills);

      // code to handel pre selected interest category

      var categoryList = response.data.response.categoryList;
      var interestCategory = response.data.response.interest_categories;
      var selectedCat = [];

      categoryList.forEach((element) => {
        for (let i = 0; i < interestCategory.length; i++) {
          if (parseInt(interestCategory[i]) === element.id) {
            let obj = {
              value: element.id,
              label: element.name,
            };
            selectedCat.push(obj);
          }
        }
      });
      // console.log("object")
      setSelectedCat(selectedCat);
      setCategoryList(response.data.response.categoryList);

      setLoading(false);
      setEditProfile(response.data.response);
      setSkillList(response.data.response.skillList);
      setExperience(response.data.response.experienceArray);
      setInterest_categories(response.data.response.interest_categories);
      setCoverLetter(response.data.response.CoverLetter);
      setOldCertificates(response.data.response.showOldImages);
      setOldDocuments(response.data.response.showOldDocs);

      // setDesignationList(response.data.response.designationlList);
      // console.log(skillList);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get data of edit profile page");
    }
  };

  const handleDocDownload = async (path, doc) => {
    setDocDownloadPath(path + doc);
    setDownloadActive(true);
    // console.log(docDownloadPath);
  };
  useEffect(() => {
    // console.log(downloadActive, DOCDownloadURL)
    if (downloadActive && docDownloadPath) {
      // Create a hidden link element
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = docDownloadPath;
      link.download = "generated-cv.doc";
      document.body.appendChild(link);

      // Trigger a click on the link
      link.click();

      // Clean up
      document.body.removeChild(link);
      setDownloadActive(false);
    }
  }, [downloadActive, docDownloadPath]);

  const handleDocumentsRemove = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Certificate?",
        text: "Do you want to delete this certificate?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteCertificate/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        Swal.fire({
          title: "Certificate deleted successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
        // console.log(response);
      }
    } catch (error) {
      Swal.fire({
        title: "Could not delete certificate. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Cannot delete certificate");
    }
  };

  const handleCertificateRemove = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Certificate?",
        text: "Do you want to delete this certificate?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteCertificate/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        Swal.fire({
          title: "Certificate deleted successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
        // console.log(response);
      }
    } catch (error) {
      Swal.fire({
        title: "Could not delete certificate. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Cannot delete certificate");
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const handleSkillChange = (selectedOptions) => {
    // Update the jobData state with the selected skills

    console.log(selectedOptions)

    setEditProfile((prevJobData) => ({
      ...prevJobData,
      skill: selectedOptions.map((option) => option.id),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (name === "skill") {
    //   setEditProfile((prevJobData) => ({
    //     ...prevJobData,
    //     skill: [...prevJobData.skill, value],
    //   }));
    // }
    if (name === "gender") {
      // console.log(value);
      if (value === "female") {
        setEditProfile({ ...editProfile, gender: "1" });
      }
      if (value === "male") {
        setEditProfile({ ...editProfile, gender: "0" });
      }
      if(value === null){
        setEditProfile({ ...editProfile, gender: "0" });
      }
    } else {
      setEditProfile((prevJobData) => ({
        ...prevJobData,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    // console.log(editProfile);
  };

  const handleClick = async () => {
    var skills = document.getElementsByName("skill");
    var skillArray = [];
    skills.forEach((element) => {
      skillList.forEach((skill) => {
        if (skill.id === parseInt(element.value)) {
          skillArray.push(skill.name);
        }
      });
    });

    // console.log(skillArray)

    // return false;

    var interest_categories = document.getElementsByName("interest_categories");

    var categoryArray = [];

    interest_categories.forEach((element) => {
      categoryArray.push(element.value);
    });


    


    // console.log(skillArray)
    // console.log(categoryArray)

    // setUpdatedSkill(skillArray);
    // setUpdatedCat(categoryArray);

    // console.log(updatedSkills);
    // console.log(updatedCat);

    // return false;

    try {
      const newErrors = {};

      if (editProfile.first_name === "" || editProfile.first_name === null) {
        newErrors.first_name = "First Name is required";
        window.scrollTo(0, 0);
      }

      if (editProfile.last_name === "" || editProfile.last_name === null) {
        newErrors.last_name = "Last Name is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.gender === "" || editProfile.gender === null) {
        newErrors.gender = "Gender is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.location === "" || editProfile.location === null) {
        newErrors.location = "Location is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.contact === "" || editProfile.contact === null) {
        newErrors.contact = "Contact Number is required";
        window.scrollTo(0, 0);
      }else if (!/^\+?\d{1,3}-?\d{9,15}$/.test(editProfile.contact)) {
        newErrors.contact = "Contact Number under 15 digits and contain only digits";
          window.scrollTo(0, 0);
      }


      if (editProfile.pre_location === "" || editProfile.pre_location === null) {
        newErrors.pre_location = "Preferred Location is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.skills === "" || editProfile.skills === null) {
        newErrors.skills = "Skill is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.exp_salary === "" || editProfile.exp_salary === null) {
        newErrors.exp_salary = "Expected Salary is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.total_exp === "" || editProfile.total_exp === null) {
        newErrors.total_exp = "Total Experience is required";
        window.scrollTo(0, 0);
      }
      if (editProfile.company_about === "" || editProfile.company_about === null) {
        newErrors.company_about = "Description is required";
        window.scrollTo(0, 0);
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: "Update Profile",
          text: "Do you want to update your profile?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const updatedProfile = {
            ...editProfile,
            CoverLetter: coverLetter,
            selectedCV: selectedCV, // Include the selected CV here
            selectedFileName: selectedFileName,
            skills: skillArray,
            interest_categories: categoryArray,
          };

          const formData = new FormData();
          formData.append("selectedCV", selectedCV);
          selectedFileName.forEach((fileName, index) => {
            formData.append(`selectedFileNames[${index}]`, fileName);
          });

          const response = await axios.post(
            BaseApi + "/candidates/editProfile",
            updatedProfile,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          setLoading(false);
          if (response.data.status === 200) {
            Swal.fire({
              title: "Profile updated successfully!",
              icon: "success",
              confirmButtonText: "Close",
            });
            navigate("/candidates/myaccount");
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Could not update profile. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not submit edit data!");
    }
  };

  const handleCoverDelete = async (id) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Remove Cover Detail?",
        text: "Do you want to remove this Cover Detail?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteCover/${id}`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        Swal.fire({
          title: "Cover Detail removed successfully!",
          icon: "success",
          confirmButtonText: "Close",
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed!",
        text: "Could not remove Education Details. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not delete cover!");
    }
  };

  const handleAddCoverLetter = () => {
    setCoverLetter([...coverLetter, { title: "", description: "" }]);
    window.scrollTo(0, document.body.scrollHeight - 10);
  };

  const handleCoverLetterChange = (e, index, field) => {
    const { value } = e.target;
    const updatedCoverLetter = [...coverLetter];
    updatedCoverLetter[index][field] = value;
    setCoverLetter(updatedCoverLetter);
  };

  const removeCoverLetterWithoutID = (indexToRemove) => {
    const updatedCoverLetter = coverLetter.filter(
      (_, index) => index !== indexToRemove
    );
    setCoverLetter(updatedCoverLetter);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  // Code for loading Location

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestionsPreferred, setSuggestionsPreferred] = useState([]);
  const [suggestionsNative, setSuggestionsNative] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`;
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      // console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // for preferred location box
  const [suggestionTakenPreferred, setSuggestionTakenPreferred] =
    useState(false);

  const handlePreferredLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTakenPreferred(false);
    if (value == "") {
      setSuggestionTakenPreferred(true);
    }
    if (value != "") {
      setErrors({
        pre_location: "",
      });
    }

    setEditProfile((prevFilter) => ({
      ...prevFilter,
      pre_location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestionsPreferred(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestionsPreferred([]);
          }
        }
      );
    }
    if (editProfile.pre_location === "") {
      setSuggestionsPreferred([]);
    }
  };

  const handlePreferredSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handlePreferredLocationChange({
      target: { name: "location", value: suggestion },
    });

    setSuggestionTakenPreferred(true);
    // Clear the suggestions
    setSuggestionsPreferred([]);
    // console.log(filterItem);
  };

  // for native location box
  const [suggestionTakenNative, setSuggestionTakenNative] = useState(false);

  const handleNativeLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTakenNative(false);
    if (value == "") {
      setSuggestionTakenNative(true);
    }
    if (value != "") {
      setErrors({
        location: "",
      });
    }

    setEditProfile((prevFilter) => ({
      ...prevFilter,
      location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestionsNative(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestionsNative([]);
          }
        }
      );
    }
    if (editProfile.location === "") {
      setSuggestionsNative([]);
    }
  };

  const handleNativeSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handleNativeLocationChange({
      target: { name: "location", value: suggestion },
    });

    setSuggestionTakenNative(true);
    // Clear the suggestions
    setSuggestionsNative([]);
    // console.log(filterItem);
  };

  return (
    <>
      <NavBar />
      <div className="container editProfile">
        <div className="row">
          <div className="col-lg-3">
            <JSSidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="mx-3 d-flex PageHeader">
                  <img src="/Images/employerSide/icon8color.png" alt="" />
                  <h3 className="">Edit Profile</h3>
                </div>
                <div className="JSEPFirstSegment">
                  {editProfile.profile_image ? (
                    <>
                      <img src={editProfile.profile_image} alt="Profile" />
                      <div className="mt-5">
                        <Link
                          to="/candidates/uploadPhoto"
                          className="btn btn-primary button1 EPChangePhoto"
                          style={{
                            backgroundColor: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                            border: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                          }}
                          onMouseEnter={handleFirstButtonMouseEnter}
                          onMouseLeave={handleFirstButtonMouseLeave}
                        >
                          CHANGE PHOTO
                        </Link>
                        {/* <div id="emailHelp" className="form-text">
                          Supported File Types: jpg, jpeg, png (Max. 1MB)
                        </div> */}
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src="/Images/jobseekerSide/dummy-profile.png"
                        alt="Profile"
                      />
                      <div className="mt-5">
                        <Link
                          to="/candidates/uploadPhoto"
                          className="btn btn-primary button1"
                          style={{
                            backgroundColor: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                            border: hoverFirstButtonColor
                              ? secondaryColor
                              : primaryColor,
                          }}
                          onMouseEnter={handleFirstButtonMouseEnter}
                          onMouseLeave={handleFirstButtonMouseLeave}
                        >
                          CHANGE PHOTO
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                <form>
                  <div className="mb-5 mt-4 mx-4">
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example1">
                        First Name<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example1"
                        className={`form-control ${
                          errors.first_name && "input-error"
                        }`}
                        placeholder="First Name"
                        name="first_name"
                        value={editProfile.first_name}
                        onChange={handleChange}
                      />
                      {errors.first_name && (
                        <div className="text-danger">{errors.first_name}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Last Name<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.last_name && "input-error"
                        }`}
                        placeholder="Last Name"
                        name="last_name"
                        value={editProfile.last_name}
                        onChange={handleChange}
                      />
                      {errors.last_name && (
                        <div className="text-danger">{errors.last_name}</div>
                      )}
                    </div>
                    <div className="radioInput form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Gender<span className="RedStar">*</span>
                      </label>
                      <div>
                        <input
                          type="radio"
                          id="male"
                          name="gender"
                          value="male"
                          checked={editProfile.gender === "0"}
                          onChange={handleChange}
                        />
                        <label className="labelMale" htmlFor="0">
                          Male
                        </label>
                        <input
                          type="radio"
                          id="female"
                          name="gender"
                          value="female"
                          checked={editProfile.gender === "1"}
                          onChange={handleChange}
                        />
                        <label htmlFor="1">Female</label>
                      </div>
                      {errors.gender && (
                        <div className="text-danger">{errors.gender}</div>
                      )}
                    </div>

                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Native Location<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.location && "input-error"
                        }`}
                        placeholder="Location"
                        name="location"
                        value={editProfile.location}
                        onChange={handleNativeLocationChange}
                      />
                      {suggestionsNative.length > 0 && (
                        <div
                          className="suggestions"
                          style={{
                            display: suggestionTakenNative ? "none" : "",
                          }}
                        >
                          <ul className="locationDropdown">
                            {suggestionsNative.map((suggestion, index) => (
                              <div key={index} className="suggestion-item">
                                <li
                                  onClick={() =>
                                    handleNativeSuggestionClick(suggestion)
                                  }
                                >
                                  <div className="eachLocation">
                                    <div className="locationIcon">
                                      <LocationOnIcon fontSize="small" />
                                    </div>{" "}
                                    <div className="locationSuggestion">
                                      {suggestion}
                                    </div>
                                  </div>{" "}
                                </li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      )}
                      {errors.location && (
                        <div className="text-danger">{errors.location}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Contact Number<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.contact && "input-error"
                        }`}
                        placeholder="Contact Number"
                        name="contact"
                        value={editProfile.contact}
                        onChange={handleChange}
                      />
                      {errors.contact && (
                        <div className="text-danger">{errors.contact}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx preferredLocationBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Preferred Job Location<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.pre_location && "input-error"
                        }`}
                        placeholder="Location"
                        name="location"
                        value={editProfile.pre_location}
                        onChange={handlePreferredLocationChange}
                      />
                      {suggestionsPreferred.length > 0 && (
                        <div
                          className="suggestions"
                          style={{
                            display: suggestionTakenPreferred ? "none" : "",
                          }}
                        >
                          <ul className="locationDropdown">
                            {suggestionsPreferred.map((suggestion, index) => (
                              <div key={index} className="suggestion-item">
                                <li
                                  onClick={() =>
                                    handlePreferredSuggestionClick(suggestion)
                                  }
                                >
                                  <div className="eachLocation">
                                    <div className="locationIcon">
                                      <LocationOnIcon fontSize="small" />
                                    </div>{" "}
                                    <div className="locationSuggestion">
                                      {suggestion}
                                    </div>
                                  </div>{" "}
                                </li>
                              </div>
                            ))}
                          </ul>
                        </div>
                      )}
                      {errors.pre_location && (
                        <div className="text-danger">{errors.pre_location}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx DashBoardCreatBx skillPackage">
                      <label className="form-label" htmlFor="form3Example3">
                        Skills
                      </label>
                      <Select
                        defaultValue={selectedSkills}
                        isMulti
                        isSearchable
                        name="skill"
                        options={skillList.map((i) => ({
                          value: i.id,
                          label: i.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleSkillChange}

                      />
                      {errors.skills && (
                        <div className="text-danger">{errors.skills}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Expected Salary<span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className={`form-control ${
                          errors.exp_salary && "input-error"
                        }`}
                        placeholder="Salary"
                        name="exp_salary"
                        value={editProfile.exp_salary}
                        onChange={handleChange}
                      />
                      {errors.exp_salary && (
                        <div className="text-danger">{errors.exp_salary}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Total Work Experience<span className="RedStar">*</span>
                      </label>
                      <select
                        className={`form-select ${
                          errors.total_exp && "input-error"
                        }`}
                        aria-label="Default select example"
                        name="total_exp"
                        value={editProfile.total_exp}
                        onChange={handleChange}
                      >
                        <option selected value="">
                          Choose Experience
                        </option>
                        {Object.entries(experience).map(([key, value]) => {
                          return (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          );
                        })}
                      </select>
                      {errors.total_exp && (
                        <div className="text-danger">{errors.total_exp}</div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        About Your Self<span className="RedStar">*</span>
                      </label>
                      <JoditEditor
                        ref={editor}
                        name="company_about"
                        value={editProfile.company_about}
                        onChange={(company_about) =>
                          handleChange({
                            target: {
                              value: company_about,
                              name: "company_about",
                            },
                          })
                        }
                      />
                      {errors.company_about && (
                        <div className="text-danger">
                          {errors.company_about}
                        </div>
                      )}
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Youtube URL
                      </label>
                      <input
                        type="text"
                        id="form3Example3"
                        className="form-control"
                        placeholder="Youtube URL"
                        name="url"
                        value={editProfile.url}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-outline mb-5 DashBoardInputBx DashBoardCreatBx skillPackage">
                      <label className="form-label" htmlFor="form3Example3">
                        Interest Categories
                      </label>
                      <Select
                        defaultValue={selectedCat}
                        isMulti
                        isSearchable
                        name="interest_categories"
                        options={categoryList.map((i) => ({
                          value: i.id,
                          label: i.name,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label htmlFor="formFile" className="form-label">
                        CV Document/Certificate
                      </label>
                      <input
                        type="file"
                        id="formFile"
                        className="form-control"
                        name="file"
                        multiple
                        onChange={(e) => {
                          const files = Array.from(e.target.files);

                          // Capture the selected file names
                          const fileNames = files.map((file) => file.name);
                          setSelectedFileName(fileNames);

                          // Convert each selected file to base64 encoding
                          Promise.all(
                            files.map((file) => convertFileToBase64(file))
                          )
                            .then((base64Array) => {
                              setSelectedCV(base64Array);
                            })
                            .catch((error) => {
                              console.error(
                                "Error converting files to base64:",
                                error
                              );
                            });
                        }}
                      />

                      <div id="emailHelp" className="form-text">
                        Supported File Types: pdf, doc and docx, gif, jpg, jpeg,
                        png (Max. 4MB)
                      </div>
                      <div id="emailHelp" className="form-text">
                        Min file size 150 x 150 pixels for image
                      </div>
                    </div>

                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label htmlFor="formFile" className="form-label">
                        Existing Certificates
                      </label>
                      <div className="ChoosPlanBx checkCertificate">
                        <div class="EPJobseekerCertificatesDetails">
                          <ul>
                            {oldCertificates.map((i, index) => {
                              return (
                                <>
                                  <li>
                                    <i
                                      class="fa-regular fa-circle-xmark jsprofileCross"
                                      onClick={() =>
                                        handleCertificateRemove(i.slug)
                                      }
                                    ></i>
                                    <i>
                                      <img
                                        className="JSmyProfileCertificateImage"
                                        src={i.image}
                                        alt="icon"
                                      />
                                    </i>
                                    {/* <span>Certificate {index + 1}</span> */}
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label htmlFor="formFile" className="form-label">
                        Existing Documents
                      </label>
                      <div className="ChoosPlanBx">
                        <div class="EPJobseekerCertificatesDetails">
                          <ul>
                            {oldDocuments.map((i, index) => {
                              return (
                                <>
                                  <li
                                    onClick={() =>
                                      handleDocDownload(i.path, i.doc)
                                    }
                                  >
                                    <i
                                      class="fa-regular fa-circle-xmark jsprofileCross"
                                      onClick={() =>
                                        handleDocumentsRemove(i.slug)
                                      }
                                    ></i>
                                    <i>{i.doc_sub}</i>
                                    {/* <span>Certificate {index + 1}</span> */}
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="form-outline mb-5 DashBoardInputBx">
                      <label htmlFor="formFile" className="form-label">
                        Cover Letter
                      </label>
                      <div className="ChoosPlanBx">
                        <button
                          type="button"
                          className="btn btn-primary button1"
                          onClick={handleAddCoverLetter}
                          style={{
                            backgroundColor: primaryColor,
                            color: "white",
                          }}
                        >
                          ADD COVER LETTER
                        </button>
                      </div>
                    </div>

                    {coverLetter.map((coverLetter, index) => (
                      <div
                        className="form-outline mb-5 DashBoardInputBx"
                        key={index}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Cover Letter Title"
                          name={`title_${index}`}
                          value={coverLetter.title}
                          onChange={(e) =>
                            handleCoverLetterChange(e, index, "title")
                          }
                        />
                        <textarea
                          className="form-control mt-2"
                          placeholder="Cover Letter Body"
                          name={`description_${index}`}
                          rows={5}
                          value={coverLetter.description}
                          onChange={(e) =>
                            handleCoverLetterChange(e, index, "description")
                          }
                        />
                        {coverLetter.id ? (
                          <>
                            <button
                              type="button"
                              className="btn navButton2 mt-2"
                              onClick={() => handleCoverDelete(coverLetter.id)}
                              style={{
                                color: hoverFourthButtonColor
                                  ? primaryColor
                                  : secondaryColor,
                                backgroundColor: "white",
                                border: hoverFourthButtonColor
                                  ? `2px solid ${primaryColor}`
                                  : `2px solid ${secondaryColor}`,
                              }}
                              onMouseEnter={handleFourthButtonMouseEnter}
                              onMouseLeave={handleFourthButtonMouseLeave}
                            >
                              Remove
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn navButton2 mt-2"
                              onClick={() => removeCoverLetterWithoutID(index)}
                              style={{
                                color: hoverFourthButtonColor
                                  ? primaryColor
                                  : secondaryColor,
                                backgroundColor: "white",
                                border: hoverFourthButtonColor
                                  ? `2px solid ${primaryColor}`
                                  : `2px solid ${secondaryColor}`,
                              }}
                              onMouseEnter={handleFourthButtonMouseEnter}
                              onMouseLeave={handleFourthButtonMouseLeave}
                            >
                              Remove
                            </button>
                          </>
                        )}
                      </div>
                    ))}

                    <div className="JSEPFinalButton">
                      <button
                        type="button"
                        className="btn btn-primary button1"
                        onClick={handleClick}
                        style={{
                          backgroundColor: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                          border: hoverFirstButtonColor
                            ? secondaryColor
                            : primaryColor,
                        }}
                        onMouseEnter={handleFirstButtonMouseEnter}
                        onMouseLeave={handleFirstButtonMouseLeave}
                      >
                        UPDATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary button2"
                        style={{
                          color: hoverThirdButtonColor
                            ? primaryColor
                            : secondaryColor,
                          backgroundColor: "white",
                          border: hoverThirdButtonColor
                            ? `2px solid ${primaryColor}`
                            : `2px solid ${secondaryColor}`,
                        }}
                        onMouseEnter={handleThirdButtonMouseEnter}
                        onMouseLeave={handleThirdButtonMouseLeave}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSEditProfile;
